import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import get from "lodash/get"

import NwHelmet from "../components/NwHelmet"
import Header from "../components/HeaderSub"
import BlogHeader from "../components/blog/BlogHeader"
import BlogFooter from "../components/blog/BlogFooter"
import BrowserUpdateMessage from "../components/BrowserUpdateMessage"
import CookieBanner from "../components/CookieBanner"

const Wrapper = styled.div`
  position: relative;
  min-height: 100%;
  padding: 1.6875rem 0 4.625rem;
  background: ${props => props.theme.roomColors[7].base};
`

const Text = styled.div`
  position: relative;
  z-index: 10;
  max-width: 40rem;
  margin: 0 auto;
  padding: 0 1.875rem;
  font-size: 1rem;
  line-height: 1.5;
  color: white;

  p {
    position: relative;
    margin: 0 0 1rem;
    padding-left: 4.375rem;
  }

  strong {
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (min-width: 600px) {
    font-size: 0.9375rem;
  }
`

export default function BlogAboutTemplate({
  data: { klaraAndOssiYaml: { title, profile_pic, header_pic, author_info }, otherBlog },
  pageContext: { lang, pagePath, author, startPageLink },
}) {
  const intl = useIntl()
  const aboutTitle = `${intl.formatMessage({ id: "about" })} ${author}`
  const headerPicFileType = (header_pic.toLowerCase().endsWith(".jpg") ? "jpeg" : header_pic.split(".").slice(-1)[0]).toLowerCase()

  return (
    <Wrapper>
      <NwHelmet
        lang={lang}
        path={pagePath}
        title={aboutTitle}
        image={{
          url: `${header_pic}?nf_resize=fit&w=200`,
          alt: `${intl.formatMessage({ id: "portraitOf" })} ${author}`,
          type: `image/${headerPicFileType}`,
          width: 200,
          height: 200,
        }}
      />
      <Header
        lang={lang}
        title={title}
        room={7}
      />
      <BlogHeader
        link={startPageLink}
        author={author}
        headerPic={header_pic}
        profilePic={profile_pic}
        isBackLink
      />
      <Text dangerouslySetInnerHTML={{ __html: get(author_info, "html") }} />
      <BlogFooter
        lang={lang}
        title={otherBlog.title}
        path={otherBlog.path}
        profilePic={otherBlog.header_pic}
      />
      <BrowserUpdateMessage />
      <CookieBanner lang={lang} />
    </Wrapper>
  )
}

export const pageQuery = graphql`
  query($lang: String, $author: String, $otherBlog: String) {
    klaraAndOssiYaml(author: { eq: $author }, lang: { eq: $lang }) {
      title
      profile_pic
      header_pic
      author_info {
        html
      }
    }
    otherBlog: klaraAndOssiYaml(author: { eq: $otherBlog }, lang: { eq: $lang }) {
      title
      path
      header_pic
    }
  }
`
